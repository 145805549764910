import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { useState, useEffect, ReactNode } from 'react';
import { NavLink as RouterLink, useLocation, useParams } from 'react-router-dom';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Grid,
  List,
  Stack,
  Button,
  Popover,
  ListItem,
  LinkProps,
  ButtonProps,
  ListSubheader,
  CardActionArea,
  Tooltip
} from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
//
import { MenuProps, MenuItemProps } from './MainNavbar';
// hooks
import useAuth from 'hooks/useAuth';
import SignInTooltip from 'components/shared/SignInTooltip';
import {
  ButtonTracking,
  TabTracking,
  stockBtnTabEventTracking,
  event_types,
  trackEvent
} from 'components/amplitute';
import { dispatch } from '../../redux/store';
import { setShowLoginModal, setShowRegistrationModal } from '../../redux/slices/user';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0
  }
}));

// ----------------------------------------------------------------------

interface RouterLinkProps extends LinkProps {
  component?: ReactNode;
  to?: string;
  end?: boolean;
  state?: any;
}

interface StyledButtonProps extends ButtonProps {
  component?: ReactNode;
  to?: string;
  state?: object;
}

const LinkStyle = styled(Link)<RouterLinkProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: '#1565d8',
  marginRight: theme.spacing(5),
  cursor: 'pointer',
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none'
  }
}));

const ListItemStyle = styled(ListItem)<RouterLinkProps>(({ theme }) => ({
  ...theme.typography.body2,
  padding: 0,
  marginTop: theme.spacing(3),
  color: theme.palette.text.secondary,
  transition: theme.transitions.create('color'),
  '&:hover': {
    color: theme.palette.text.primary
  }
}));

const StyledJoinFreeButton = styled(Button)<StyledButtonProps>(({ theme }) => ({
  marginRight: theme.spacing(5),
  borderRadius: theme.spacing(2),
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '16px',
  padding: '10px 15px'
}));

// ----------------------------------------------------------------------

export type IconBulletProps = {
  type?: 'subheader' | 'item';
};

function IconBullet({ type = 'item' }: IconBulletProps) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 })
        }}
      />
    </Box>
  );
}

type MenuDesktopItemProps = {
  item: MenuItemProps;
  isOpen: boolean;
  isHome: boolean;
  isOffset: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
};

function MenuDesktopItem({
  item,
  isHome,
  isOpen,
  isOffset,
  onOpen,
  onClose
}: MenuDesktopItemProps) {
  const { title, path, children, redirectURL, menuItemOnClick, signInToolTip, screenName } = item;
  const { isAuthenticated, logout, user } = useAuth();
  const pathParams = useParams();

  const navItemClick = () => {
    if (title === 'My Account') {
      trackEvent('click_my_account_top_menu', {
        ScreenName: screenName ?? 'Top Menu',
        buttonName: 'My Account',
        path: window.location.href
      });
    }
    if (title === 'Weekly Tops') {
      trackEvent('click_weekly_top_menu', {
        ScreenName: screenName ?? 'Top Menu',
        buttonName: 'Weekly Tops',
        path: window.location.href
      });
    }
    if (title === 'Pricing') {
      trackEvent('click_pricing_top_menu', {
        ScreenName: screenName ?? 'Top Menu',
        buttonName: 'Pricing',
        path: window.location.href
      });
    }
    if (title === 'Sign out') {
      logout();
    }
  };

  const getRedirectURL = (url: string) => {
    let rtnURL = url;
    Object.keys(pathParams).forEach((item: string) => {
      const paramVal = pathParams[item] || '';
      rtnURL = rtnURL.replace(`:${item}`, paramVal);
    });
    return rtnURL;
  };

  const menuItemClick = (item: { [key: string]: any }) => {
    if (window.location.href.includes('/insidertrading/profile') && item.trackingInsiderEventName) {
      trackEvent(item.trackingInsiderEventName, {
        ScreenName: 'InsiderTrading',
        path: window.location.href
      });
    } else {
      stockBtnTabEventTracking(
        'Desktop Menu',
        '',
        event_types.btnClick,
        `${item.title}`,
        `${item.title}`,
        window.location.href
      );
    }

    onOpen();
  };

  if (children) {
    return (
      <>
        <LightTooltip
          arrow
          title={
            isAuthenticated ? (
              ''
            ) : (
              <SignInTooltip
                redirectURL="/dashboard/analytics"
                actionType="profile"
                stockwishlistSymbol=""
              />
            )
          }
        >
          <LinkStyle
            onClick={() => menuItemClick(item)}
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              color: 'primary.main',
              ...(isOpen && { opacity: 0.48 })
            }}
          >
            {title}
            <Box
              component={Icon}
              icon={isOpen ? arrowIosUpwardFill : arrowIosDownwardFill}
              sx={{ ml: 0.5, width: 16, height: 16 }}
            />
          </LinkStyle>
        </LightTooltip>

        <Popover
          open={isOpen}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 80, left: 0 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={onClose}
          PaperProps={{
            sx: {
              px: 3,
              pt: 5,
              pb: 3,
              right: 16,
              m: 'auto',
              borderRadius: 2,
              maxWidth: (theme) => theme.breakpoints.values.lg,
              boxShadow: (theme) => theme.customShadows.z24
            }
          }}
        >
          <Grid container spacing={3}>
            {children.map((list) => {
              const { subheader, items } = list;

              return (
                <Grid key={subheader} item xs={12} md={subheader === 'Dashboard' ? 6 : 2}>
                  <List disablePadding>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: 'flex',
                        lineHeight: 'unset',
                        alignItems: 'center',
                        color: 'text.primary',
                        typography: 'overline'
                      }}
                    >
                      <IconBullet type="subheader" /> {subheader}
                    </ListSubheader>

                    {items.map((item) => (
                      <ListItemStyle
                        key={item.title}
                        to={item.path}
                        component={RouterLink}
                        onClick={() => {
                          stockBtnTabEventTracking(
                            'Dashboard',
                            item.title,
                            event_types.tabClick,
                            item.title,
                            item.path,
                            window.location.href
                          );
                        }}
                        underline="none"
                        sx={{
                          '&.active': {
                            color: 'text.primary',
                            typography: 'subtitle2'
                          }
                        }}
                      >
                        {item.title === 'Dashboard' ? (
                          <CardActionArea
                            sx={{
                              py: 5,
                              px: 10,
                              borderRadius: 2,
                              color: 'primary.main',
                              bgcolor: 'background.neutral'
                            }}
                          >
                            <Box
                              component={motion.img}
                              whileTap="tap"
                              whileHover="hover"
                              variants={{
                                hover: { scale: 1.02 },
                                tap: { scale: 0.98 }
                              }}
                              src="/static/illustrations/illustration_dashboard.png"
                            />
                          </CardActionArea>
                        ) : (
                          <>
                            <IconBullet />
                            {item.title}
                          </>
                        )}
                      </ListItemStyle>
                    ))}
                  </List>
                </Grid>
              );
            })}
          </Grid>
        </Popover>
      </>
    );
  }

  if (title === 'Documentation') {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        sx={{
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' })
        }}
        onClick={() => {
          stockBtnTabEventTracking(
            'Desktop Menu',
            '',
            event_types.btnClick,
            `${title}`,
            `${title}`,
            window.location.href
          );
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  if (title === 'Sign in') {
    return isAuthenticated ? null : (
      <LinkStyle
        sx={{
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' })
        }}
        onClick={() => {
          dispatch(
            setShowLoginModal({
              path: window.location.href,
              source: 'navbar',
              btnName: 'Sign in'
            })
          );
          trackEvent('click_sign_in', {
            path: window.location.href,
            source: 'navbar'
          });
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  if (title === 'Pricing') {
    return (
      <LinkStyle
        href={path}
        sx={{
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' })
        }}
        onClick={() => {
          trackEvent('click_pricing', {
            path: window.location.href,
            source: 'navbar'
          });
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  if (title === 'Home') {
    return (
      <LinkStyle
        href={path}
        sx={{
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' })
        }}
        onClick={() => {
          trackEvent('click_home', {
            path: window.location.href,
            source: 'navbar'
          });
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  if (title === 'Blog') {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        sx={{
          color: 'black',
          ...(isOffset && {
            color: 'black'
          }),
          '&.active': {
            color: 'primary.main'
          }
        }}
        onClick={() => {
          stockBtnTabEventTracking(
            'Desktop Menu',
            '',
            event_types.btnClick,
            `${title}`,
            `${title}`,
            window.location.href
          );
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  if (title === 'Sign in') {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        sx={{
          color: 'black',
          ...(isOffset && {
            color: 'black'
          }),
          '&.active': {
            color: 'primary.main'
          }
        }}
        onClick={() => {
          stockBtnTabEventTracking(
            'Desktop Menu',
            '',
            event_types.btnClick,
            `${title}`,
            `${title}`,
            window.location.href
          );
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  return (
    <LightTooltip
      arrow
      title={
        isAuthenticated || signInToolTip === false ? (
          ''
        ) : (
          <SignInTooltip
            redirectURL="/dashboard/analytics"
            actionType="profile"
            stockwishlistSymbol=""
          />
        )
      }
    >
      <LinkStyle
        to={getRedirectURL(path)}
        className="weekly-top-activities-trading"
        component={RouterLink}
        end={path === '/'}
        sx={{
          ...(isOffset && {
            color: 'black'
          }),
          '&.active': {
            color: 'primary.main'
          }
        }}
        onClick={(e) => {
          if (title === 'Sign out') {
            trackEvent('click_sign_out', {
              path: window.location.href,
              source: 'navbar'
            });
          } else {
            stockBtnTabEventTracking(
              'Desktop Menu',
              '',
              event_types.btnClick,
              `${title}`,
              `${title}`,
              window.location.href
            );
          }
          onOpen();
          menuItemOnClick && navItemClick();
        }}
      >
        {title}
      </LinkStyle>
    </LightTooltip>
  );
}

export default function MenuDesktop({ isOffset, isHome, navConfig }: MenuProps) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const { isAuthenticated, user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openItem = Boolean(anchorEl);
  const handleClickItem = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseItem = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getPath = (link: any) => {
    if (link?.path?.indexOf(':encId') && user?.encId) {
      link.path = link.path.replace(':encId', user?.encId);
    }

    return link;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const joinFreeBtnClick = () => {
    dispatch(
      setShowRegistrationModal({
        path: window.location.href,
        source: 'Nav header menu',
        btnName: 'Join button'
      })
    );
    trackEvent('click_join_for_free', {
      ScreenName: 'InsiderTrading',
      path: window.location.href,
      source: 'navbar'
    });
  };

  const usersHideEmailAlerts = [
    'haris.widjaja@gmail.com',
    'tahneetran3@gmail.com',
    'mconnolly9@gmail.com',
    'cherrypikky@gmail.com',
    'jingjingdu.cmu@gmail.com',
    'mcoron@gmail.com',
    'gfwv0@fthcapital.com',
    'dspencer5544@outlook.com',
    'nozin@yandex.ru',
    'fabienfabien20633@gmail.com',
    'tterry911@yahoo.com',
    'gaspar_terrazas@hotmail.com',
    'activoenredes@gmail.com',
    'lingjian0315@163.com',
    'jafs03@gmail.com',
    'amulcotton816@gmail.com',
    'jaypretium@gmail.com',
    'paprotpaprocki@gmail.com',
    'willapthompson@gmail.com',
    'gil.zwick@gmail.com',
    'michael.sesser@gmail.com',
    'adonice.fagbohoun@gmail.com',
    'alexvinci50@gmail.com',
    'artlikemedia@gmail.com',
    'chidyy98@gmail.com',
    'gilles.cedric@gmail.com',
    'jgates@1620ia.com',
    'saif2alharbi@gmail.com',
    'yabsirasl@gmail.com'
  ];

  const menuItemClick = (item: any) => {
    if (item?.title === 'Daily Top Insider Buys') {
      trackEvent('click_daily_insider_buys_top_menu', {
        ScreenName: item.screenName ?? 'Top Menu',
        buttonName: 'Daily Top Insider Buys',
        path: window.location.href
      });
    }

    if (item?.title === 'Top Buys in the Past 30 Days') {
      trackEvent('click_daily_insider_top_buys_past_30days_top_menu', {
        ScreenName: item.screenName ?? 'Top Menu',
        buttonName: 'Top Buys in the Past 30 Days',
        path: window.location.href
      });
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      {!isAuthenticated && (
        <StyledJoinFreeButton variant="contained" onClick={joinFreeBtnClick}>
          Join
        </StyledJoinFreeButton>
      )}
      {navConfig
        .filter(
          (link: any) =>
            link.isAuthenticated == isAuthenticated || link.isAuthenticated == undefined
        )
        .map((link: any) => {
          if (
            link.title == 'Email Alerts' &&
            isAuthenticated &&
            user &&
            usersHideEmailAlerts.indexOf(user.email) > -1
          ) {
            return;
          }
          const formattedLink = getPath(link);
          return link.childItems ? (
            <div style={{ paddingRight: '20px' }}>
              <Button
                id="basic-button"
                className="premium-trading-activity"
                aria-controls={openItem ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openItem ? 'true' : undefined}
                onClick={handleClickItem}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ fontWeight: 600 }}
              >
                {link.title}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openItem}
                onClose={handleCloseItem}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                {link.childItems.map((litem: any, k: number) => (
                  <MenuItem onClick={handleCloseItem} key={k} sx={{ padding: 0 }}>
                    <Link
                      component={RouterLink}
                      to={litem.path}
                      onClick={() => menuItemClick(litem)}
                      sx={{ fontSize: '14px', padding: '8px 16px' }}
                    >
                      {litem.title}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ) : (
            <MenuDesktopItem
              key={formattedLink.title}
              item={formattedLink}
              isOpen={open}
              onOpen={handleOpen}
              onClose={handleClose}
              isOffset={isOffset}
              isHome={isHome}
            />
          );
        })}
    </Stack>
  );
}
