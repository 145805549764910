import React, { useState, useEffect, useRef } from 'react';
import { setShowRegistrationModal } from '../redux/slices/user';
import { useDispatch } from '../redux/store';
// components
import { setTheme } from '../redux/slices/theme';
import { useLocation } from 'react-router-dom';
import { PATH_INSIDER_TRADING } from '../routes/paths';
import { RootState, useSelector } from '../redux/store';

// ----------------------------------------------------------------------

export default function ModalPopupTimer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const messageRef = useRef('');
  const { showLoginRegistrationModal } = useSelector((state: RootState) => state?.user);

  const subscribeBtnClick = () => {
    let isAuth = localStorage.getItem('accessToken') ? true : false;
    if (!isAuth && !messageRef.current) {
      dispatch(
        setShowRegistrationModal({
          path: window.location.href,
          source: 'Timer',
          btnName: 'Subscribe'
        })
      );
    }
  };

  useEffect(() => {
    messageRef.current = showLoginRegistrationModal;
  }, [showLoginRegistrationModal]);

  useEffect(() => {
    // Check if the user is on the landing page
    const isOnLandingPage = location.pathname === PATH_INSIDER_TRADING.root;
    const isOnAlgoTradingShowcasePage =
      location.pathname === PATH_INSIDER_TRADING.algo_trading_showcase;

    // If not on landing page, set shouldRenderModal to true
    if (!isOnLandingPage && !isOnAlgoTradingShowcasePage) {
      setShouldRenderModal(true);
    }

    dispatch(setTheme('bluebg'));

    return () => {
      dispatch(setTheme(''));
    };
  }, [location.pathname]);

  useEffect(() => {
    // Only trigger the modal popup if shouldRenderModal is true
    if (shouldRenderModal) {
      const timer = setTimeout(subscribeBtnClick, 300000); // 5 mins

      return () => clearTimeout(timer);
    }
  }, [shouldRenderModal, dispatch]); // Run only once on component mount

  return null;
}
