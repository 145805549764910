import React, { useState, useCallback, useEffect } from 'react';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Switch,
  Container,
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
// components
import Page from '../../components/Page';
import { PricingPlanCard } from '../../components/_external-pages/pricing';
import { trackEvent } from '../../components/amplitute';

//
import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../../assets';
import ContactFloatingButton from 'layouts/main/ContactFloatingButton';
import { RootState, dispatch, useDispatch, useSelector } from 'redux/store';
import { createCheckoutSession, createCustomerPortalSession } from 'redux/slices/subscription';
import { useSnackbar } from 'notistack';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

// ----------------------------------------------------------------------

// eslint-disable-next-line no-restricted-globals
const pageHost = location?.host || '';

const PLANS = [
  {
    subscriptionName: 'basic',
    icon: <PlanFreeIcon />,
    price: 0,
    caption: 'forever',
    lists: [
      { text: 'Corporate Insider Trades', isAvailable: true },
      { text: 'Track Record of Corporate Insider Purchase', isAvailable: true },
      { text: 'Top Weekly Insider Trades Newsletter/Blog', isAvailable: true },
      { text: 'All Free Features on the platform', isAvailable: true }
      // { text: '3 boards', isAvailable: true },
      // { text: 'Up to 5 team members', isAvailable: false },
      // { text: 'Advanced security', isAvailable: false },
      // { text: 'Permissions & workflows', isAvailable: false }
    ],
    labelAction: 'Free Plan',
    priceId_Monthly: null,
    priceId_Annually: null
  },
  {
    subscriptionName: 'Daily Traders',
    icon: <PlanStarterIcon />,
    price: 4.99,
    caption: 'save 20% a year',
    lists: [
      { text: 'Everything in Basic Plan', isAvailable: true },
      {
        text: 'Top Daily Insider Purchase delivered at 8:30am EST',
        isAvailable: true,
        highlight: 'Daily Insider Purchase'
      },
      { text: 'Top Buys in the Last 30 days', isAvailable: true }

      // { text: 'Up to 5 team members', isAvailable: true },
      // { text: 'Advanced security', isAvailable: false },
      // { text: 'Permissions & workflows', isAvailable: false }
    ],
    labelAction: 'Subscribe',
    priceId_Monthly: pageHost.includes('localhost')
      ? 'price_1PIED9B9T7SWhMSHSeFBgAsi'
      : 'price_1PLGk0B9T7SWhMSHG5bo3pLD',
    priceId_Annually: pageHost.includes('localhost')
      ? 'price_1PIED9B9T7SWhMSHHhbKtIYA'
      : 'price_1PLGk0B9T7SWhMSHTfmWPuSQ'
  }
  // {
  //   subscriptionName: 'Real-Time Traders',
  //   icon: <PlanPremiumIcon />,
  //   price: 7.99,
  //   caption: 'save 20% a year',
  //   lists: [
  //     { text: 'Everything in Daily Traders Plan', isAvailable: true },
  //     {
  //       text: 'Insider Buy Email Alert delivered in Real-Time',
  //       isAvailable: true,
  //       highlight: 'Real-Time'
  //     }
  //   ],
  //   labelAction: 'Subscribe',
  //   priceId_Monthly: 'price_1PIEE4B9T7SWhMSHG012Dzmf',
  //   priceId_Annually: 'price_1PIEEfB9T7SWhMSHPB7poUQt'
  // }
];

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main
      }
    },
    color: theme.palette.primary.main // Primary color when unchecked
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.main
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main
  }
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

// ----------------------------------------------------------------------

export default function Pricing({ planChange, title1, title2 }: any) {
  const [isYearly, setIsYearly] = useState(false);

  const dispatch = useDispatch();
  const { subscription } = useSelector((state: RootState) => state.subscription);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsYearly(event.target.checked);
  // };

  useEffect(() => {
    trackEvent('view_pricing', {
      path: window.location.href,
      source: planChange === false ? 'Viewed Pricing Modal' : 'Viewed Pricing Page'
    });
  }, []);

  const [alignment, setAlignment] = useState('monthly');

  const handleAlignment = (event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      if (newAlignment === 'monthly') {
        setIsYearly(false);
      } else {
        setIsYearly(true);
      }
    }
  };

  const [priceId, setPriceId] = useState();
  const handlePackageSelection = async (priceId: any) => {
    if (priceId) {
      trackEvent('click_subscribe', {
        tenure: isYearly ? 'Yearly' : 'Monthly',
        ScreenName: 'Pricing',
        path: window.location.href
      });
    }
    if (planChange) {
      try {
        // await dispatch(updateSubscription(priceId));
        // enqueueSnackbar('Subscription Updated', {
        //   variant: 'success'
        // });
        // setChangePlan(false);
        dispatch(createCustomerPortalSession({}));
      } catch (error) {
        enqueueSnackbar(error?.message ?? 'Error Occurred', {
          variant: 'error'
        });
      }
    } else {
      setPriceId(priceId);
      dispatch(createCheckoutSession({ priceId }));
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 5 }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          {/* <Typography variant="overline" sx={{ mr: 1.5 }}>
            MONTHLY
          </Typography>
          <MaterialUISwitch checked={isYearly} onChange={handleChange} />
          <Typography variant="overline" sx={{ ml: 1.5 }}>
            YEARLY
          </Typography> */}

          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            color="primary"
          >
            {/* @ts-ignore */}
            <StyledToggleButton
              color="primary"
              value="monthly"
              // variant="contained"
              sx={{ width: 100 }}
            >
              Monthly
            </StyledToggleButton>
            {/* @ts-ignore */}
            <StyledToggleButton
              color="primary"
              value="yearly"
              // variant="contained"
              sx={{ width: 100 }}
            >
              Yearly
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>

      <Grid container spacing={3} justifyContent={'center'}>
        {title1 ? <h1>{title1}</h1> : null}
        {title2 ? <h4 style={{ textAlign: 'center' }}>{title2}</h4> : null}
        {PLANS.map((card, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} md={4} key={card.subscriptionName}>
              <PricingPlanCard
                card={card}
                index={index}
                isYearly={isYearly}
                handlePackageSelection={handlePackageSelection}
              />
            </Grid>
          </React.Fragment>
        ))}
        <Grid xs={12}>
          <Box p={3}>
            <Typography variant="caption">
              By confirming your subscription, you allow Trading Town Space to charge you for your
              future payments in accordance with their terms. You can always cancel your
              subscription.
            </Typography>
            <br />
            <Typography variant="caption">
              By clicking Subscribe, you agree to the Terms and Privacy Policy.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <ContactFloatingButton />
    </Container>
  );
}
