import { styled } from '@mui/material/styles';
import SimpleBarReact, { Props } from 'simplebar-react';
import { useState, memo, useRef } from 'react';
import { Link } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import moment from 'moment';
import { Pagination, Table, TableRow, TableCell, TableHead, TableBody } from '@mui/material';
import { Notification } from './Notification';
import { Follow } from './FollowInsider';
import reStrcutureForm4Link from '../../utils/formatForm4URL';
import { trackEvent } from '../../components/amplitute';
import useAuth from 'hooks/useAuth';
import { setShowRegistrationModal } from '../../redux/slices/user';
import { useDispatch } from '../../redux/store';

const ListContainer = styled('div')(({ theme }) => ({
  width: '100%',
  marginBottom: '10px',
  overflow: 'auto',
  table: {
    width: '100%'
  },
  thead: {
    background: '#F6F9FE',
    height: '36.882px',
    borderTop: '2px solid #000',
    borderBottom: '2px solid #000',
    fontSize: '12.237px',
    fontWeight: 500,
    lineHeight: '18.355px'
  },
  'thead th > div': {
    cursor: 'pointer'
  },
  'tbody tr': {
    height: '59.856px'
  },
  'thead img': {
    display: 'inline',
    marginLeft: '5px',
    alignSelf: 'center',
    filter: 'opacity(0.3)'
  },
  'th div': {
    display: 'flex'
  },
  '.asc img': {
    transform: 'rotate(180deg)'
  },
  '.desc, .asc': {
    cursor: 'pointer',
    img: {
      filter: 'opacity(1)'
    }
  },
  '.buy': {
    backgroundColor: '#FFFFFF',
    borderBottom: '2px solid #B8B8B8'
  },
  '.sell': {
    backgroundColor: '#FFFFFF;',
    borderBottom: '2px solid #B8B8B8'
  },
  '.date-fld': {
    borderRadius: '30px',
    backgroundColor: '#F2F4F7',
    padding: '2.039px 8.158px',
    minWidth: '70px',
    display: 'block',
    textAlign: 'center'
  },
  '.dt-field-container': {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    svg: {
      marginTop: '5px',
      color: '#1565D8'
    }
  },
  '.follow-notify': {
    display: 'flex',
    width: '194px',
    'button:first-child': {
      borderRadius: '30px',
      marginRight: '10px',
      boxShadow: 'none',
      minWidth: '114px'
    },
    'button:last-child': {
      border: '1px solid #1565D8',
      svg: {
        width: '24px',
        height: '16px'
      }
    },
    '.notiEnabled': {
      backgroundColor: '#1565D8',
      svg: {
        color: '#fff'
      }
    }
  },
  '.scrollbar': {
    paddingTop: '50px',
    '.simplebar-horizontal': {
      backgroundColor: '#F1F6FE',
      top: '10px',
      '.simplebar-scrollbar': {
        backgroundColor: '#1565D8',
        borderRadius: '30px'
      }
    }
  },
  '.insiderTitle, .insiderName': {
    width: '150px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('lg')]: {
      wordBreak: 'normal'
    }
  },
  '.MuiPagination-root': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '45px',
    '.Mui-selected': {
      backgroundColor: '#1565D8',
      color: '#fff'
    },
    '.MuiPaginationItem-ellipsis': {
      border: '0'
    },
    '.MuiPagination-ul': {
      flexWrap: 'nowrap',
      li: {
        '&:first-child': {
          flexBasis: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          button: {
            border: '0',
            '.MuiPaginationItem-icon': {
              display: 'none'
            }
          },
          '> button::after': {
            marginLeft: '10px',
            content: '"Prev"'
          }
        },
        '&:last-child': {
          flexBasis: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          button: {
            border: '0',
            '.MuiPaginationItem-icon': {
              display: 'none'
            }
          },
          '> button::before': {
            marginRight: '10px',
            content: '"Next"'
          }
        }
      }
    }
  }
}));

const ActivitiesList = memo(function ActivitiesList(props: any) {
  console.log('props:', props);
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const [sortColumn, setSortColumn] = useState({ column: 'date', order: 'desc' });
  const colRef = useRef<HTMLTableRowElement>(null);

  const heading = [
    {
      title: 'Ticker',
      sorting: false,
      sortKey: 'ticker',
      className: 'ticker'
    },
    {
      title: 'Date',
      sorting: false,
      sortKey: 'date',
      className: ''
    },
    {
      title: 'Transaction',
      sorting: false,
      sortKey: 'transaction',
      className: ''
    },
    {
      title: 'Value',
      sorting: false,
      sortKey: 'value',
      className: ''
    }
  ];

  const formatCurrency = (amt: number) => {
    return new Intl.NumberFormat('en-US').format(amt);
  };

  const trackFileClick = (link: string) => {
    trackEvent('click_sec_doc', {
      link_to_doc: link,
      ScreenName: 'InsiderTrading',
      path: window.location.href
    });
  };

  const showRegistrationModalOnFileClick = (url: string) => {
    dispatch(
      setShowRegistrationModal({
        path: window.location.href,
        source: 'Form 4',
        btnName: 'File click'
      })
    );
    trackFileClick(url);
  };

  const getFileLink = (item: any) => {
    if (item.xmlFile) {
      const url = reStrcutureForm4Link(item.xmlFile);
      return isAuthenticated || item.transactionCode !== 'P' ? (
        <a target="_blank" href={url} title="Form-4" onClick={() => trackFileClick(url)}>
          <InsertDriveFileIcon />
        </a>
      ) : (
        <a href="#" onClick={(e) => e.preventDefault()}>
          <InsertDriveFileIcon onClick={() => showRegistrationModalOnFileClick(url)} />
        </a>
      );
    }

    return null;
  };

  return (
    <ListContainer>
      <SimpleBarReact className="scrollbar" autoHide={false}>
        <Table>
          <TableHead>
            <TableRow ref={colRef}>
              {heading.map((item) =>
                props.isProfile && item.sortKey === 'follow' ? null : (
                  <TableCell
                    key={item.title}
                    className={
                      sortColumn.column === item.sortKey
                        ? `${item.className} ${sortColumn.order}`
                        : item.className
                    }
                  >
                    <div>
                      {item.title}
                      {item.sorting ? (
                        <img src="/static/insidertrading/arrow-down.png" alt="arrow" />
                      ) : null}
                    </div>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.insiderTradingActivities?.map((item: any, i: number) => (
              <TableRow key={i} className={item.lastActivity === 'entry' ? 'buy' : 'sell'}>
                <TableCell className="ticker">
                  <a href={`https://finance.yahoo.com/quote/${item.ticker}`} target="_blank">
                    {item.ticker}
                  </a>
                </TableCell>
                <TableCell>
                  <div className="dt-field-container">
                    <span className="date-fld">
                      {moment
                        .utc(item.lastActivityDate)
                        .format(props.isProfile ? 'MMM, Do YYYY' : 'MMM, Do YYYY')}
                    </span>
                    {getFileLink(item)}
                  </div>
                </TableCell>
                <TableCell>{item.lastActivity === 'entry' ? 'Buy' : 'Sell'}</TableCell>
                <TableCell className="totalValue">
                  {isNaN(item.entryValue) ? '' : `$${formatCurrency(Math.ceil(item.entryValue))}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SimpleBarReact>
    </ListContainer>
  );
});

export default ActivitiesList;
