import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const campaign = searchParams.get('utm_campaign');

  useEffect(() => {
    if (campaign !== 'twitter_congress_landingpage') {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
