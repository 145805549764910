import { useEffect, useState } from 'react';
import { useDispatch } from '../../redux/store';
// material
import { styled } from '@mui/material/styles';
import { Container, CircularProgress, Backdrop } from '@mui/material';
import { useParams, Link, useNavigate } from 'react-router-dom';
// routes
import Page from '../../components/Page';
import { getThirtyDaysTopBuysList } from '../../redux/slices/top-insider-buys';
import { RootState, useSelector } from '../../redux/store';
import useAuth from 'hooks/useAuth';
import { trackEvent } from '../../components/amplitute';
import { getSubscription } from 'redux/slices/subscription';
import PricingModal from 'pages/pricing/PricingModal';
import moment from 'moment';
import { setShowRegistrationModal } from '../../redux/slices/user';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const BreadCrumb = styled('div')(({ theme }) => ({
  color: '#000',
  fontSize: '12px',
  ul: {
    display: 'flex',
    listStyle: 'none',
    gap: '7px',
    whiteSpace: 'nowrap'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  marginTop: '120px',
  minHeight: '300px',
  '.hide-filters': {
    opacity: 0,
    display: 'none',
    transition: 'opacity 0.5s ease-out;'
  },
  '.show-filters': {
    opacity: 1,
    display: 'flex',
    transition: 'opacity 0.5s ease-out;',
    '@media (min-width: 900px) and (max-width: 1150px)': {
      alignItems: 'center'
    }
  },
  '.content-ctn': {
    paddingTop: '20px',
    paddingBottom: '20px',
    overflow: 'auto'
  }
}));

export default function TopBuys() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const { subscription } = useSelector((state: RootState) => state.subscription);
  const navigate = useNavigate();

  const {
    thirtyDays = '',
    isLoadingThiryDays,
    dailyError
  } = useSelector((state: RootState) => state.topInsiderBuys);

  useEffect(() => {
    // if (!isAuthenticated) {
    //   navigate('/');
    //   return;
    // }
    dispatch(getThirtyDaysTopBuysList());
  }, []);

  useEffect(() => {
    const expiresAt = subscription?.expires_at
      ? moment.utc(subscription.expires_at).local().valueOf()
      : 0;
    if (
      isAuthenticated &&
      subscription &&
      Object.keys(subscription).length &&
      subscription?.status !== 'active'
    ) {
      if (moment().toDate().getTime() > expiresAt) {
        //Show modal
        triggerModal();
      }
    }
  }, [subscription]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSubscription());
      dispatch(getThirtyDaysTopBuysList());
    } else {
      dispatch(
        setShowRegistrationModal({
          path: window.location.href,
          source: `Top buys last 30 days`,
          btnName: `Onload Top buys last 30 days`
        })
      );
    }
  }, [isAuthenticated]);

  const onClosePricing = () => {
    setShowModal(false);
  };

  const triggerModal = () => {
    setShowModal(true);
  };

  const clickHandler = (e: React.MouseEvent) => {
    const target = e.target as HTMLAnchorElement;
    const event: string = target?.href?.includes('activities')
      ? 'click_insider_trader_actvities'
      : target?.href?.includes('profile')
      ? 'click_insider_trader_profile'
      : '';

    const howItWorks: boolean = target?.href?.includes('how-it-works');
    if (howItWorks) {
      trackEvent('click_insider_trader_how_it_works', {
        ScreenName: 'TopBuysLastThirtyDays',
        path: window.location.href,
        TargetURL: target?.href
      });
    }

    if (event) {
      trackEvent(event, {
        ScreenName: 'DailyTopInsiderBuys',
        path: window.location.href,
        TargetURL: target?.href
      });
    }
  };

  return (
    <RootStyle title="Daily top insider buys | TradingTown Space">
      <Container>
        <ContentStyle>
          <BreadCrumb>
            <ul>
              <li>
                <Link to={'/'}>TradingTown</Link>
              </li>
              <li> {'> '}Top Buys in the Last 30 Days</li>
            </ul>
          </BreadCrumb>
          <div
            className="content-ctn"
            onClick={clickHandler}
            dangerouslySetInnerHTML={{ __html: thirtyDays }}
          ></div>
        </ContentStyle>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'rgba(22,28,36, 0.5)' }}
          open={isLoadingThiryDays}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <PricingModal showModal={showModal} redirectURL={''} onClose={onClosePricing} />
      </Container>
    </RootStyle>
  );
}
