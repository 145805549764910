import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import { trackEvent } from 'components/amplitute';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/v1/user/me');
          const { user } = response.data;

          if (user) {
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user
              }
            });
          } else {
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
          }
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/api/v1/auth/login', {
      email,
      password
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const register = async (
    email: string,
    password: string,
    name: string,
    joinWaitList?: boolean,
    subscribeToAlerts?: boolean
  ) => {
    const response = await axios.post('/api/v1/auth/signup', {
      email,
      password,
      name,
      joinWaitList,
      subscribeToAlerts
    });
    const { accessToken, user } = response.data;
    setSession(accessToken);
    trackEvent('complete_sign_up', {
      sign_up_method: 'email',
      action: 'Sign Up Completed'
    });
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
    return response.data;
  };

  const googleLogin = async (obj: any) => {
    const response = await axios.post('/api/v1/auth/google', obj);

    const { accessToken, user } = response.data;

    setSession(accessToken);
    trackEvent('complete_sign_up', {
      sign_up_method: 'google',
      action: 'Sign Up Completed'
    });
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const fbLogin = async (obj: any) => {
    const response = await axios.post('/api/v1/auth/fb', obj);

    const { accessToken, user } = response.data;

    setSession(accessToken);
    trackEvent('complete_sign_up', {
      sign_up_method: 'facebook',
      action: 'Sign Up Completed'
    });
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = async (data: any) => {
    const response = await axios.post('/api/v1/user/reset', data);
    return response?.data;
  };

  const updateProfile = () => {};

  const forgotPassword = async (email: string) => {
    const response = await axios.post('/api/v1/user/forgot', {
      email
    });
    return true;
  };

  const verifyEmail = async (userId?: string, token?: string) => {
    try {
      const response = await axios.get(`/api/v1/user/${userId}/verify/${token}`);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
        updateProfile,
        googleLogin,
        fbLogin,
        verifyEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
